import React, { forwardRef } from "react";
import {
  StyledContentWrapper,
  StyledMediaContainer,
  StyledMessageContainer,
  StyledMessageRow,
  StyledMessageTimeStamps,
} from "../../Styles/StyledComponents";
import { getMessageTime } from "../../utils/utils";
import AppChatUserAvatar from "./AppChatUserAvatar";
interface Props {
  children: React.ReactNode;
  userMessage: boolean;
  isMedia?: boolean;
  isButton?: boolean;
  divStyle?: React.CSSProperties;
  rowStyle?: React.CSSProperties;
  time?: string;
  ref?: any;
  readStatus?: "sent" | "delivered" | "seen";
}

const AppMessageBoxWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      userMessage = false,
      isMedia = false,
      isButton = false,
      divStyle,
      rowStyle,
      children,
      time = "a",
      readStatus = "delivered",
    },
    ref
  ) => {
    if (isMedia) {
      return (
        <StyledMessageRow
          userMessage={userMessage}
          style={rowStyle}
          isButton={isButton}
          className={"styledMessageRow"}
        >
          <AppChatUserAvatar isButton={isButton} userMessage={userMessage} />
          <StyledContentWrapper userMessage={userMessage}>
            <StyledMediaContainer
              userMessage={userMessage}
              style={divStyle}
              isButton={isButton}
            >
              {children}
            </StyledMediaContainer>
            <StyledMessageTimeStamps
              userMessage={userMessage}
              isButton={isButton}
            >
              {getMessageTime(time)}
              {/* <AppChatReadReceipts
              userMessage={userMessage}
              status={readStatus}
            /> */}
            </StyledMessageTimeStamps>
          </StyledContentWrapper>
        </StyledMessageRow>
      );
    }

    return (
      <StyledMessageRow
        userMessage={userMessage}
        style={rowStyle}
        className={"styledMessageRow"}
        ref={ref}
      >
        <AppChatUserAvatar userMessage={userMessage} />
        <StyledContentWrapper userMessage={userMessage}>
          <StyledMessageContainer userMessage={userMessage} style={divStyle}>
            {children}
          </StyledMessageContainer>
          <StyledMessageTimeStamps
            userMessage={userMessage}
            isButton={isButton}
          >
            {getMessageTime(time)}

            {/* <AppChatReadReceipts userMessage={userMessage} status={readStatus} /> */}
          </StyledMessageTimeStamps>
        </StyledContentWrapper>
      </StyledMessageRow>
    );
  }
);

export default AppMessageBoxWrapper;
