import React, { forwardRef } from "react";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import { StyledMessageText } from "./../../Styles/StyledComponents";

interface Props {
  userMessage: boolean;
  children: any;
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  ref?: any;
  [otherProps: string]: any;
}
const AppRenderText: React.FC<Props> = React.memo(
  forwardRef<HTMLDivElement, Props>(
    (
      {
        isFullScreen = false,
        userMessage = false,
        children,
        time,
        readStatus = "delivered",

        ...otherProps
      },
      ref
    ) => {
      const [isSpeaking, setIsSpeaking] = React.useState(false);

      const speakText = (htmlText: any) => {
        if ("speechSynthesis" in window) {
          // Use a DOMParser to parse the HTML and extract text content
          const parser = new DOMParser();
          const doc = parser.parseFromString(htmlText || "", "text/html");
          const text = doc.body.textContent || "";

          if (text.trim() === "") {
            // No text to speak, return early
            return;
          }

          const utterance = new SpeechSynthesisUtterance(text);

          // Set additional options for the utterance if needed
          // utterance.voice = ...
          // utterance.rate = ...

          // Event handler for when speech ends
          utterance.onend = () => {
            setIsSpeaking(false);
          };

          // Start or stop speaking based on the current state
          if (isSpeaking) {
            window.speechSynthesis.cancel();
          } else {
            window.speechSynthesis.speak(utterance);
          }
          setIsSpeaking(!isSpeaking);
        }
      };

      if (!children || children?.length < 1) {
        return null;
      }

      return (
        <AppMessageBoxWrapper
          userMessage={userMessage}
          time={time}
          isMedia={false}
          readStatus={readStatus}
          ref={ref}
        >
          {/* Text To Speech */}
          {/* {userMessage ? null : (
          <Box
            className="speaker"
            sx={{ position: "absolute", right: "-8px", top: "-6px" }}
          >
            <TextToSpeechButton text={children} />
          </Box>
        )} */}

          <StyledMessageText
            userMessage={userMessage}
            dangerouslySetInnerHTML={{
              __html: children,
            }}
            {...otherProps}
          />
        </AppMessageBoxWrapper>
      );
    }
  )
);

export default AppRenderText;
